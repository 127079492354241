










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: { CustomSelect },
})
export default class LorFilter extends Vue {
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(UserServiceS) private userService!: UserService;

    get items(): Item[] {
        const { availableLors, availableCalendarLors } = this.fleetFiltersService;
        const lors = this.currentPath === '/fleet-density/table' ? availableLors : availableCalendarLors;
        if (!lors) {
            return [];
        }

        if (!this.currentValue || !lors.find(lor => Number(lor) === Number(this.currentValue))) {
            this.currentValue = Number(lors[0]);
        }

        return lors.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        return this.fleetService.storeState.settings.lor;
    }
    set currentValue(value) {
        if (value) {
            this.fleetFiltersService.saveLor(value);
        }
    }

    get currentPath() {
        return this.$route.path;
    }
}
